import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SearchBar } from 'views/common/components';

export const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: 'transparent',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column-reverse',
  },
  '& .MWGMapSide': {
    height: theme.spacing(76.5),
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(32.75),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(31.25),
    },
    overflow: 'hidden',
  },
  '& .MWGLocationListSide': {
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: 50,
        background:
          'linear-gradient(rgba(255, 255, 255, 0.001), rgba(255, 255, 255, 0.9))',
      },
    },
  },
  '& .MWGMapPlaceholder': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  '& .MWGTitleSection': {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
      paddingTop: theme.spacing(2),
    },
  },
  '& .MWGMainTitle': {
    color: theme.palette.secondary.main,
    paddingRight: theme.spacing(1.25),
  },
  '& .MWGLocationsNumber': {
    marginLeft: theme.spacing(1.25),
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginLeft: theme.spacing(0),
    },
  },
  '& .MWGWeight300': {
    fontWeight: '300',
  },
  '&.MWGDark': {
    '& .MWGMainTitle': {
      color: theme.palette.common.fogWhite,
    },
    '& .MWGLocationsNumber': {
      color: theme.palette.grey[200],
    },
    '& .MWGLocationListSide': {
      '&:after': {
        content: 'unset',
      },
    },
  },
}));
export const StyledSearchBar = styled(SearchBar)(({ theme }) => ({
  '& .SBInputBase': {
    width: '100%',
    '& .MuiInputBase-input': {
      width: '100%',
    },
  },
  '&.MWGDark': {
    '& .SBInputBase .MuiInputBase-input': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));
