import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Pin = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="33"
    viewBox="0 0 23 33"
    fill="none">
    <path
      d="M11.5 0C5.14214 0 0 5.1645 0 11.55C0 14.421 0.821428 17.1105 2.31643 19.536C3.87714 22.077 5.93071 24.255 7.50786 26.796C8.28 28.0335 8.83857 29.1885 9.43 30.525C9.85714 31.4325 10.2021 33 11.5 33C12.7979 33 13.1429 31.4325 13.5536 30.525C14.1614 29.1885 14.7036 28.0335 15.4757 26.796C17.0529 24.2715 19.1064 22.0935 20.6671 19.536C22.1786 17.1105 23 14.421 23 11.55C23 5.1645 17.8579 0 11.5 0ZM11.5 16.0875C9.23286 16.0875 7.39286 14.2395 7.39286 11.9625C7.39286 9.6855 9.23286 7.8375 11.5 7.8375C13.7671 7.8375 15.6071 9.6855 15.6071 11.9625C15.6071 14.2395 13.7671 16.0875 11.5 16.0875Z"
      fill="black"
    />
    <rect x="4" y="4.5" width="15" height="15" rx="7.5" fill="#EDC14F" />
  </SvgIcon>
);

export default Pin;
