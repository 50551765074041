import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledMarkerBox = styled(Box)(({ theme }) => ({
  '&.markerCard': {
    position: 'relative',
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(42),
    // lineHeight: theme.spacing(5),
    // background: theme.palette.primary.main,
    // border: `${theme.spacing(0.125)} solid ${theme.palette.primary.dark}`,
    // borderRadius: theme.spacing(0.5),
    // marginBottom: theme.spacing(3.75),
    // textAlign: 'center',
    // // color: theme.palette.common.fogWhite,
    // display: 'flex',
    // alignItems: 'flex-start',
    // justifyContent: 'center',
    // padding: theme.spacing(1),
    // transition: ' all ease .5s',
    // transform: 'translate(-50%, -50%)',
    // '&:after': {
    //   position: 'absolute',
    //   bottom: theme.spacing(-1.25),
    //   left: '50%',
    //   marginLeft: theme.spacing(-1.25),
    //   content: '""',
    //   display: 'block',
    //   borderLeft: `${theme.spacing(1.25)}  solid transparent`,
    //   borderRight: `${theme.spacing(1.25)}  solid transparent`,
    //   borderTop: `${theme.spacing(1.25)}  solid ${theme.palette.primary.main}`,
    // },
    '& .GWOrderNum': {
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      position: 'absolute',
      top: theme.typography.pxToRem(8),
      fontWeight: 700,
      left: 0,
      right: 0,
      textAlign: 'center',
    },
    '& .MGWPin': {
      width: '100%',
      height: '100%',
    },
    '&.active': {
      zIndex: 1,
      '& .MGWPin': {
        '& path': {
          fill: theme.palette.primary.main,
        },
        '& rect': {
          fill: theme.palette.common.white,
        },
      },
    },
  },
}));

export default StyledMarkerBox;
