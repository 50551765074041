import React, { useMemo, useState } from 'react';
import { MapWithGroups } from 'views/common/components';
import { Typography } from '@mui/material';
import StyledMarkerBox from 'views/common/components/Logical/MapGroupWrapper/MapGroupWrapper.styles';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import Pin from 'public/imgs/mapGroups/Pin';

const MainWidgetDialog = dynamic(() => import('views/common/components/UI/MainWidgetDialog').then(
  (file) => file.MainWidgetDialog,
));
const ContactUsDialog = dynamic(() => import(
  'views/common/components/Logical/Layout/Footer/NeedHelp/ContactUsDialog'
));

const DEFAULT_ZOOM = 5;
const SELECTED_LOC_ZOOM = 8;

const MapGroupWrapper = (props) => {
  const {
    mapGroupData,
    mapGroupsTitle,
    widgetDialogProps = {},
    ...restOfProps
  } = props;
  const {
    reservationData = {},
    firstCallParams = {},
    ...restOfDialogParams
  } = widgetDialogProps;
  const { t } = useTranslation('fe_er_common_map_with_groups');
  const appState = useSelector((state) => state.globalData.appState);

  const locationsList = useMemo(() => {
    const groupsLocNums = mapGroupData.locations_per_place.map(
      (locationGroup) => locationGroup.locations.length,
    );
    for (let i = 1; i < groupsLocNums.length; i += 1) {
      groupsLocNums[i] += groupsLocNums[i - 1];
    }
    return mapGroupData.locations_per_place.map(
      (locationGroup, groupIndex) => ({
        name: locationGroup.place?.name,
        slug: locationGroup.place?.slug,
        locations: locationGroup.locations.map((loc, locIndex) => ({
          ...loc,
          order:
            (groupIndex - 1 >= 0 ? groupsLocNums[groupIndex - 1] : 0)
            + locIndex
            + 1,
        })),
      }),
    );
  }, [mapGroupData]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [visibleLocations, setVisibleLocations] = useState(locationsList);
  const [mainWidgetDialog, setMainWidgetDialog] = useState({ open: false });
  const [shouldRenderDialog, setShouldRenderDialog] = useState({
    contactUs: false,
  });
  const prepareMapUrl = (url) => {
    if (!url.startsWith('http')) {
      return `https://${url}`;
    }
    return url;
  };
  const handleMarkerClick = (order, location) => {
    const locationsGroupsList = document.getElementById('locationsList');
    const itemToScrollTo = document.getElementById(`item_${location.id}`);

    setSelectedLocation(() => ({
      ...location,
      position: {
        lat: Number(location.lat),
        lng: Number(location.lng),
      },
      zoom: SELECTED_LOC_ZOOM,
    }));

    locationsGroupsList.scrollTo({
      top:
        itemToScrollTo.getBoundingClientRect().top
        - locationsGroupsList.getBoundingClientRect().top
        + locationsGroupsList.scrollTop,
      behavior: 'smooth',
    });
  };
  const renderMapMarkers = () => {
    const locationsMarkers = [];

    locationsList.forEach((locationsGroup) => {
      locationsGroup.locations.forEach((location) => {
        locationsMarkers.push({
          ...location,
          ...mapGroupData.map_info.markers.find(
            (loc) => loc.id === location.id,
          ),
        });
      });
    });

    return locationsMarkers.map((locationMarker) => (
      <StyledMarkerBox
        {...locationMarker}
        lat={+locationMarker.lat}
        lng={+locationMarker.lng}
        key={locationMarker.order}
        className={`markerCard ${
          locationMarker.id === selectedLocation.id && 'active'
        }`}>
        <Pin className="MGWPin" />
        <Typography component="span" variant="body2" className="GWOrderNum">
          {locationMarker.order}
        </Typography>
      </StyledMarkerBox>
    ));
  };
  const handleLocationCardHover = (locationId) => {
    if (locationId === selectedLocation.id) return;
    const newLoc = mapGroupData.map_info.markers.find(
      (mapLoc) => mapLoc.id === locationId,
    );

    if (!newLoc) return;

    setSelectedLocation(() => ({
      ...newLoc,
      position: {
        lat: Number(newLoc.lat),
        lng: Number(newLoc.lng),
      },
      zoom: SELECTED_LOC_ZOOM,
    }));
  };
  const defaultMapCenter = useMemo(
    () => ({
      lat: +mapGroupData.map_info.center.lat,
      lng: +mapGroupData.map_info.center.lng,
    }),
    [mapGroupData],
  );
  const handleTextFieldSearch = (e) => {
    const filteredLocations = [];
    const query = e.target.value;
    if (!query) {
      setVisibleLocations(locationsList);
      return;
    }
    locationsList.forEach((locationGroup) => {
      locationGroup.locations.forEach((loc) => {
        if (loc.name.toLowerCase().includes(query.toLowerCase())) {
          const foundLocGroupIdx = filteredLocations.findIndex(
            (foundLoc) => foundLoc.name === locationGroup.name,
          );
          if (foundLocGroupIdx === -1) {
            filteredLocations.push({ ...locationGroup, locations: [loc] });
          } else {
            filteredLocations[foundLocGroupIdx].locations.push(loc);
          }
        }
      });
    });
    setVisibleLocations(filteredLocations);
  };
  const handleBookNowButtonCLick = (id) => {
    setMainWidgetDialog({ open: true, id });
  };
  const handleMainWidgetDialogClose = () => {
    setMainWidgetDialog({ open: false });
  };
  const handleEmailButtonClick = () => {
    setShouldRenderDialog({ contactUs: true });
  };
  if (!mapGroupData?.locations_count > 0) return null;
  return (
    <>
      <MapWithGroups
        prepareMapUrl={prepareMapUrl}
        mapCenter={selectedLocation.position || defaultMapCenter}
        handleLocationCardHover={handleLocationCardHover}
        handleLocationCardButtonClick={handleLocationCardHover}
        selectedLocationId={selectedLocation.id}
        handleTextFieldSearch={handleTextFieldSearch}
        locationsGroup={visibleLocations}
        defaultZoom={selectedLocation.zoom || DEFAULT_ZOOM}
        renderMapMarkers={renderMapMarkers}
        handleMapLogoClick={handleMarkerClick}
        mapGroupsTitle={
          mapGroupsTitle
          || t('fe_er_common_map_with_groups:participating_locations')
        }
        handleBookNowButtonCLick={handleBookNowButtonCLick}
        locationCount={mapGroupData.locations_count}
        handleEmailButtonClick={handleEmailButtonClick}
        {...restOfProps}
      />
      {mainWidgetDialog.open && (
        <MainWidgetDialog
          open={mainWidgetDialog.open}
          handleClose={handleMainWidgetDialogClose}
          reservationData={{
            pickup_location_id: mainWidgetDialog.id,
            dropoff_location_id: mainWidgetDialog.id,
            ...reservationData,
          }}
          floatingDialog
          renderFullWidgetByForce
          fixPosition
          firstCallParams={firstCallParams}
          {...restOfDialogParams}
        />
      )}
      {shouldRenderDialog.contactUs && (
        <ContactUsDialog
          open={shouldRenderDialog.contactUs}
          onClose={() => {
            setShouldRenderDialog({
              ...shouldRenderDialog,
              contactUs: false,
            });
          }}
          title={t('fe_er_common_map_with_groups:contact_eaglerider')}
          userId={appState.current_user ? appState.current_user.id : null}
        />
      )}
    </>
  );
};

export { MapGroupWrapper };
