import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: theme.spacing(1, 1, 1, 0),
  width: '100%',
  borderRadius: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    '& .SBSearchIconWrapper': {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.black,
      zIndex: 1,
    },
  },
  '& .SBInputBase': {
    color: 'inherit',
    '& .MuiInputBase-input': {
      backgroundColor: theme.palette.grey.A000,
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(${theme.spacing(2)} + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      borderRadius: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(40),
      },
    },
  },
}));
export default StyledDiv;
