import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { styled } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';

const StyledGrid = styled(Grid)(({ theme }) => ({
  '& .bookingLocation': {
    position: 'relative',
    '& .locationContiner': {
      display: 'inline-block',
      maxWidth: '95%',
      '& .address': {
        color: theme.palette.secondary.dark,
        display: 'flex',
        alignItems: 'flex-start',
        [theme.breakpoints.down('lg')]: {
          fontSize: theme.typography.pxToRem(14),
        },
        '& .locationIcon': {
          marginRight: theme.spacing(1),
          color: theme.palette.grey[300],
          fontSize: theme.typography.pxToRem(20),
          position: 'relative',
          top: theme.spacing(0.5),
          [theme.breakpoints.down('lg')]: {
            fontSize: theme.typography.pxToRem(17),
          },
        },
        '&.operatorAdress': {
          color: theme.palette.secondary.dark,
          fontSize: theme.spacing(1.75),
          fontWeight: '400',
        },
      },
    },
    '& .LocationLink': {
      position: 'relative',
      top: theme.spacing(-0.25),
      '& .DirectionBox': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(3.5),
        '& .text': {
          color: theme.palette.common.black,
          fontSize: theme.spacing(1.5),
          lineHeight: theme.spacing(3.25),
          fontWeight: 700,
          textDecoration: 'underline',
        },
      },
      '& .getLocationIcon': {
        marginLeft: theme.spacing(1),
        color: theme.palette.common.black,
        fontSize: theme.typography.pxToRem(14),
        '&.withoutTitle': {
          position: 'absolute',
          top: theme.spacing(-0.25),
        },
      },
    },
    '& .details': {
      color: theme.palette.grey[300],
      marginLeft: theme.spacing(3.75),
      whiteSpace: 'pre-line',
      marginTop: theme.spacing(1),
      '&.operatordetails': {
        color: theme.palette.secondary.dark,
        marginTop: theme.spacing(0),
      },
    },
  },
}));

const Address = (props) => {
  const {
    location,
    addressPath,
    details,
    titlePath,
    target,
    isDealer,
    className,
    mapUrl,
  } = props;
  const { t } = useTranslation('fe_er_common_address');

  return (
    <StyledGrid item xs={12}>
      <div className={`bookingLocation ${className}`}>
        <Box className="locationContiner">
          <Typography
            className={`address ${!isDealer && 'operatorAdress'}`}
            variant="h5"
            component="span">
            <LocationOnIcon className="locationIcon" />
            {location}
          </Typography>
        </Box>

        {details && (
          <Box>
            <Typography
              className={`details ${!isDealer && 'operatordetails'}`}
              variant="body2">
              {details}
            </Typography>
          </Box>
        )}

        {/* display only when send prop link */}
        {addressPath && (
          <NavigatorLink
            href={addressPath}
            mapUrl={mapUrl}
            underline="none"
            className="LocationLink"
            target={target}>
            {titlePath ? (
              // if display Icon link with get directions text
              <Box className="DirectionBox">
                <Typography500 className="text" variant="h5" component="span">
                  {t('fe_er_common_address:get_directions')}
                </Typography500>
                <OpenInNewRoundedIcon className="getLocationIcon" />
              </Box>
            ) : (
              // if display Icon link without get directions text
              <OpenInNewRoundedIcon className="getLocationIcon withoutTitle" />
            )}
          </NavigatorLink>
        )}
      </div>
    </StyledGrid>
  );
};

export { Address };
