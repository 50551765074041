import React from 'react';
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Button,
  Grid,
  alpha,
} from '@mui/material';
import MuiLink from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EmailIcon from '@mui/icons-material/Email';
import Typography500 from 'views/common/components/UI/Typography500';
import { Address } from 'views/common/components/UI/Address';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.locationCard': {
    border: `${theme.spacing(0.125)} solid ${theme.palette.grey[50]}`,
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(0, 2, 2, 0),
    padding: theme.spacing(1, 2),
    transition: 'all .5s ease',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
      margin: theme.spacing(0, 0, 2, 0),
    },
    backgroundColor: theme.palette.common.white,
    '& .locationIndex': {
      float: 'right',
      color: theme.palette.common.black,
    },
    '& .listItem': {
      padding: 0,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'inline-flex',
        marginRight: theme.spacing(2),
        width: 'auto',
        alignItems: 'center',
      },
      '& .emailBtn': {
        padding: theme.spacing(0),
        fontWeight: '500',
        textTransform: 'uppercase',
        minWidth: 'unset',
        color: theme.palette.secondary.light,
      },
    },
    '& .listText': {
      fontWeight: 500,
      textTransform: 'uppercase',
      color: theme.palette.secondary.light,
    },
    '& .ListIcon': {
      marginRight: theme.spacing(0.5),
      minWidth: 0,
      writingMode: 'vertical-rl',
    },
    '& .icon': {
      width: theme.spacing(2),
      color: theme.palette.common.black,
    },
    '& .bookGrid': {
      marginTop: theme.spacing(1),
      '& .bookBtnGrid': {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
          justifyContent: 'start',
        },
      },
    },
    '&:hover': {
      border: `${theme.spacing(0.125)} solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.A000,
      cursor: 'pointer',
    },
    '&.active': {
      border: `${theme.spacing(0.125)} solid ${theme.palette.primary.main}`,
      // backgroundColor: theme.palette.common.fogWhite,
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      cursor: 'pointer',
      '& .MuiButton-root.MuiButton-contained': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.fogWhite,
      },
    },
    '&.LCDark': {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.common.fogWhite,
      '& .address.operatorAdress': {
        color: `${theme.palette.common.fogWhite} !important`,
      },
      '&:hover': {
        borderColor: theme.palette.success.main,
      },
    },
  },
}));

const LocationCard = (props) => {
  const { t } = useTranslation('fe_er_common_location_card');
  const {
    id,
    title,
    phone,
    location,
    address,
    link,
    handleEmailButtonClick,
    locationIndex,
    handleBookNowButtonCLick,
    activeCard,
    target,
    handleLocationCardButtonClick = () => {},
    handleLocationCardHover = () => {},
    darkMode,
  } = props;
  return (
    <StyledBox
      id={`item_${id}`}
      className={`locationCard ${activeCard ? 'active' : ' '} ${
        darkMode && 'LCDark'
      }`}
      onClick={() => {
        handleLocationCardButtonClick(id);
      }}
      onMouseEnter={() => handleLocationCardHover(id)}>
      <Typography500 component="p" variant="h5" my={1.25}>
        {title}
        <Typography500 component="span" variant="h5" className="locationIndex">
          {locationIndex}
        </Typography500>
      </Typography500>
      <Address location={address} addressPath={link} target={target} />

      <Grid container columnSpacing={{ lg: 1, md: 0 }} className="bookGrid">
        <Grid item lg={8} md={12} xs={12}>
          <List>
            <ListItem className="listItem">
              <ListItemIcon className="ListIcon">
                <PhoneIcon className="icon" />
              </ListItemIcon>
              <Typography component="p" variant="body2">
                <MuiLink
                  href={`tel:${phone}`}
                  underline="none"
                  className="listText">
                  {t('fe_er_common_location_card:call')}
                </MuiLink>
              </Typography>
            </ListItem>
            <ListItem className="listItem">
              <ListItemIcon className="ListIcon">
                <EmailIcon className="icon" />
              </ListItemIcon>
              <Button
                disableElevation
                variant="text"
                className="emailBtn"
                onClick={handleEmailButtonClick}>
                {t('fe_er_common_location_card:email')}
              </Button>
            </ListItem>
            <ListItem className="listItem">
              <ListItemIcon className="ListIcon">
                <LocationCityIcon className="icon" />
              </ListItemIcon>
              <NavigatorLink href={location}>
                <Typography variant="body2" component="p" className="listText">
                  {t('fe_er_common_location_card:location_details')}
                </Typography>
              </NavigatorLink>
            </ListItem>
          </List>
        </Grid>
        <Grid item lg={4} md={12} xs={12} className="bookBtnGrid">
          <Button
            disableElevation
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIosIcon />}
            onClick={() => {
              handleBookNowButtonCLick(id);
            }}>
            {t('fe_er_common_location_card:book_now')}
          </Button>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export { LocationCard };
