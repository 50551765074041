import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(0.5),
    maxHeight: theme.spacing(62.75),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
    },
    '&::-webkit-scrollbar-track ': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey[400],
      borderRadius: theme.spacing(0.5),
    },
    '& .LIGTitle': {
      fontSize: theme.spacing(2.5),
      textDecoration: 'underline',
      marginBottom: theme.spacing(1),
      color: theme.palette.secondary.main,
    },
    '& .LIGOtherLoc': {
      textDecoration: 'initial',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      display: 'none',
    },
    '& .MuiAccordion-root': {
      boxShadow: 'none',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
      width: 'fitContent',
      cursor: 'auto',
    },
    '& .LLGButtonContiner': {
      display: 'none',
    },
  },
  [theme.breakpoints.down('lg')]: {
    '& .MuiAccordion-root ': {
      boxShadow: 'none',
      marginTop: theme.spacing(1),
      '&:before': {
        top: theme.spacing(-1),
      },
      '&.Mui-expanded:before': {
        opacity: 1,
      },
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
    '& .LLGAccordionContiner': {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    '& .LLGButtonContiner': {
      textAlign: 'center',
      marginTop: theme.spacing(1),
    },
    '& .LIGTitle': {
      fontSize: theme.spacing(2.5),
      color: theme.palette.secondary.main,
    },
  },
  '&.LLGDark': {
    '& .MuiAccordion-root ': {
      backgroundColor: 'transparent',
    },
    '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
      color: theme.palette.grey[200],
    },
    '& .LIGTitle': {
      fontSize: theme.spacing(2.5),
      color: theme.palette.common.fogWhite,
    },
    '& .LLGAccordionContiner': {
      borderBottom: '0px',
    },
  },
}));
export default StyledBox;
